import styled from 'styled-components'
import { device } from '../../theme'

export const SectionWrap = styled.div`
  padding-bottom: 100px;
  padding-top: 40px;
  background: ${(props) => props.theme.colors.background};

  @media ${device.medium} {
    padding-bottom: 80px;
  }

  @media ${device.small} {
    padding-bottom: 60px;
    padding-top: 0;
  }

  .box-item {
    margin-top: 40px;
  }
`

export const TopContent = styled.div`
  position: relative;
  min-height: 160px;
  margin-top: -80px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 5;
`

import styled, { css } from 'styled-components'
import { device } from '../../../theme'

const Heading = css`
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.black};
`

export const SectionWrapper = styled.div`
  position: relative;
`

export const Title = styled.h2`
  ${Heading}

  @media ${device.small} {
    display: none;
  }
`

export const OffCanvasTitle = styled.h2`
  ${Heading}
  margin-bottom: 0;
`

export const FilterButton = styled.button`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: -20px;
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.textDark};

  svg {
    color: ${(props) => props.theme.colors.icon};
    margin-right: 10px;
    font-size: 28px;
    transition: ${(props) => props.theme.transition};
  }

  &:hover svg {
    color: ${(props) => props.theme.colors.theme};
  }
`

export const TabContainer = styled.div`
  margin-top: 40px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  @media ${device.small} {
    margin-top: 0;
  }

  > a {
    display: block;
    padding: 10px 0;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    color: ${(props) => props.theme.colors.textDark};

    &.active {
      pointer-events: none;
      color: ${(props) => props.theme.colors.theme};
    }
  }

  select {
    border: 1px solid ${(props) => props.theme.colors.border};

    @media ${device.smallOnly} {
      display: none;
    }

    &:focus {
      background-color: ${(props) => props.theme.colors.background} !important;
    }
  }

  .accordion {
    border: none;
  }

  .accordion__button {
    position: relative;
    padding: 10px 0;
    background-color: transparent;
    border: solid ${(props) => props.theme.colors.border};
    border-width: 1px 0 0 0;

    &::before {
      position: absolute;
      top: 18px;
      right: 0;
      height: 8px;
      width: 8px;
    }

    &[aria-expanded='true']::before {
      top: 16px;
    }

    .active {
      color: ${(props) => props.theme.colors.theme};
    }
  }

  .accordion__panel {
    padding-right: 0;
    padding-left: 0;
  }
`

export const TabList = styled.ul``

export const TabItem = styled.li`
  border-left: 1px solid ${(props) => props.theme.colors.border};

  ${(props) =>
    props.active &&
    css`
      border-left: 2px solid ${(props) => props.theme.colors.theme};
    `}

  > a {
    padding: 8px 20px;
    color: ${(props) => props.theme.colors.textDark};
    font-family: ${(props) => props.theme.fontFamily.heading};

    ${(props) =>
      props.active &&
      css`
        pointer-events: none;
        color: ${(props) => props.theme.colors.theme};
      `}

    &:hover {
      color: ${(props) => props.theme.colors.theme};
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import BoxLargeImage from '../../../components/box-large-image/layout-two'
import { slugify } from '../../../utils/utilFunctions'

const List = ({ path, items, detailButtonText, boxStyles }) => {
  return (
    <Container>
      <Row>
        {items &&
          items.length > 0 &&
          items.map((item) => {
            return (
              <Col
                lg={4}
                md={6}
                className="box-item"
                key={`box-image-${item.id}`}
              >
                <BoxLargeImage
                  {...boxStyles}
                  category={item.date}
                  imageSrc={item.image}
                  title={item.title}
                  path={`${path}/${slugify(item.slug)}`}
                  btnText={detailButtonText}
                />
              </Col>
            )
          })}
      </Row>
    </Container>
  )
}

List.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  buttontext: PropTypes.string,
  detailButtonText: PropTypes.string,
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  boxStyles: PropTypes.object,
}

List.defaultProps = {
  path: '/actueel/nieuws',
  title: 'Actueel',
  buttontext: 'Meer actueel',
  detailButtonText: 'Bekijk artikel',
  sectionTitleStyle: {
    mb: '20px',
    responsive: {
      medium: {
        mb: '10px',
      },
    },
  },
  boxStyles: {
    headingStyle: {
      color: '#000',
    },
  },
  linkStyle: {
    layout: 'underline',
    fontSize: '18px',
    fontWeight: 500,
    lineheight: 1.4,
    color: 'primary',
    hover: {
      layout: 2,
    },
  },
  sectionBottomStyle: {
    mt: '40px',
    align: 'center',
  },
}

export default List

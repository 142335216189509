import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ScrollToTop from '../../components/ui/scroll-to-top'
import SEO from '../../components/seo'
import Header from '../../containers/header'
import Footer from '../../containers/footer'
import Hero from '../../components/hero'
import Pagination from '../../components/blog/pagination'
import CategoryTabs from '../../containers/news/category-tabs'
import { Container, Row, Col } from '../../components/ui/wrapper'
import { SectionWrap, TopContent } from './overview-layout.style'
import { slugify } from '../../utils/utilFunctions'

// import '../../assets/fonts/oswald/oswald.css'
import '../../assets/fonts/dia/dia.css'
import '../../assets/fonts/opensans/opensans.css'
import '../../assets/css/bootstrap.css'

const OverviewLayout = ({
  topContent,
  children,
  location,
  pageContext,
  pageData,
}) => {
  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const {
    content,
    currentPage,
    numberOfPages,
    category,
    parentCategory,
    type,
    absolutePath,
    sidebar,
    crumbTitle,
  } = pageContext
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
  } = pageData

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <main id="main" className="site-wrapper-reveal">
      <SEO
        image={headerImage?.file?.url}
        title={
          currentPage
            ? category
              ? `${category} ${type}: pagina ${currentPage}`
              : `${
                  type.charAt(0).toUpperCase() + type.slice(1)
                }: pagina ${currentPage}`
            : `${category || headerTitel}`
        }
        description={
          currentPage
            ? category
              ? `${
                  headerSubtitel || headerTitel
                } | ${category} ${type}: pagina ${currentPage}`
              : `${headerSubtitel || headerTitel} | ${
                  type.charAt(0).toUpperCase() + type.slice(1)
                }: pagina ${currentPage}`
            : category
            ? `${headerSubtitel || headerTitel} | ${category} ${type}`
            : `${headerSubtitel || headerTitel} | ${
                type.charAt(0).toUpperCase() + type.slice(1)
              }`
        }
        metadata={pageContext.metadata}
      />
      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />

      <Hero
        flyoutHandler={flyoutHandler}
        title={category ? category : headerTitel}
        crumbTitle={category ? category : crumbTitle ? crumbTitle : headerTitel}
        description={headerSubtitel}
        buttontext={headerButtonTekst}
        buttonlink={headerButtonLink}
        image={headerImage}
        pageContext={pageContext}
        showLastLable={false}
      />

      {topContent && (
        <Container>
          <Row>
            <Col>
              <TopContent>{topContent}</TopContent>
            </Col>
          </Row>
        </Container>
      )}

      <SectionWrap className="container-xl">
        <Row>
          <Container fluid="lg">
            <Row>
              {sidebar && (
                <Col md={3}>
                  <CategoryTabs
                    path={absolutePath}
                    type={type}
                    content={content}
                    current={category}
                    parentCategory={parentCategory}
                  />
                </Col>
              )}

              <Col md={sidebar ? 9 : 12}>
                {children}
                <Pagination
                  rootPage={
                    category
                      ? parentCategory
                        ? `${absolutePath}/${slugify(parentCategory)}/${slugify(
                            category,
                          )}`
                        : `${absolutePath}/${slugify(category)}`
                      : absolutePath
                  }
                  currentPage={currentPage}
                  numberOfPages={numberOfPages}
                  parentCategory={parentCategory}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </SectionWrap>

      <Footer />
      <ScrollToTop />
    </main>
  )
}

OverviewLayout.propTypes = {
  children: PropTypes.node,
  transition: PropTypes.bool,
  subCategory: PropTypes.bool,
  pageContext: PropTypes.shape({
    absolutePath: PropTypes.string.isRequired,
    currentPage: PropTypes.number,
    numberOfPages: PropTypes.number,
    type: PropTypes.string.isRequired,
    category: PropTypes.string,
    sidebar: PropTypes.bool.isRequired,
  }),
  pageData: PropTypes.shape({
    headerTitel: PropTypes.string.isRequired,
    headerSubtitel: PropTypes.string,
    headerButtonTekst: PropTypes.string,
    headerButtonLink: PropTypes.string,
    headerImage: PropTypes.object,
  }),
}

export default OverviewLayout

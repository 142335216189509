import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import OverviewLayout from '../../containers/layout/overview-layout'
import List from '../../containers/news/list'

const NewsList = ({ data, pageContext, location }) => {
  pageContext.crumbTitle = 'Nieuws'

  const subsidyNews = pageContext.content.news
    .filter((edge) => edge.node.isSubsidyNews === true)
    .map((edge) => edge.node)
  const paginatedNews = subsidyNews.splice(pageContext.skip, pageContext.limit)

  return (
    <OverviewLayout
      location={location}
      pageContext={pageContext}
      pageData={data.page.edges[0].node}
      subCategory={pageContext.subCategory}
    >
      {pageContext.category === 'Subsidie' ? (
        <List items={paginatedNews} path={pageContext.absolutePath} />
      ) : (
        <List
          items={
            pageContext.subCategory
              ? data.subList.edges.map((edge) => edge.node)
              : data.list.edges.map((edge) => edge.node)
          }
          path={pageContext.absolutePath}
        />
      )}
    </OverviewLayout>
  )
}

export const query = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!, $category: String) {
    page: allContentfulPagina(
      filter: { id: { eq: "8368dcf5-829e-5fc5-a2c2-31501bad6c74" } }
    ) {
      edges {
        node {
          headerTitel
          headerSubtitel
          headerButtonTekst
          headerButtonLink
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    list: allContentfulNieuwsbericht(
      sort: { fields: date, order: DESC }
      filter: {
        themes: { elemMatch: { title: { eq: $category } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          description {
            description
          }
          content {
            json
          }
          themes {
            id
            title
          }
          thumbnail: image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    subList: allContentfulNieuwsbericht(
      sort: { fields: date, order: DESC }
      filter: {
        subjects: { elemMatch: { title: { eq: $category } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          description {
            description
          }
          content {
            json
          }
          themes {
            id
            title
          }
          subjects {
            id
            slug
            title
            theme {
              id
              url
              title
            }
          }
          thumbnail: image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

NewsList.propTypes = {
  headingStyle: PropTypes.object,
}

export default NewsList

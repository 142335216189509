import React from 'react'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import {
  PaginationWrap,
  PaginationList,
  PaginationItem,
  PaginationLink,
} from './pagination.style'

const Pagination = ({ currentPage, numberOfPages, rootPage }) => {
  const isFirst = !currentPage || currentPage === 1
  const isLast = !currentPage || currentPage === numberOfPages
  const previousPage =
    currentPage - 1 === 1
      ? rootPage
      : rootPage + '/' + (currentPage - 1).toString()
  const nextPage = rootPage + '/' + (currentPage + 1).toString()

  return (
    <PaginationWrap>
      {numberOfPages > 1 && (
        <PaginationList>
          {!isFirst && (
            <PaginationItem classname="prev">
              <PaginationLink
                title="Vorige"
                aria-label="Ga een pagina terug"
                path={previousPage}
                state={{ transition: false }}
              >
                <FaChevronLeft />
              </PaginationLink>
            </PaginationItem>
          )}
          {Array.from({ length: numberOfPages }, (_, i) =>
            currentPage === i + 1 ? (
              <PaginationItem key={`page-number-${i + 1}`}>
                <PaginationLink
                  active="true"
                  path={`${rootPage}/${i === 0 ? '' : 'page/' + (i + 1)}`}
                  state={{ transition: false }}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ) : (
              <PaginationItem key={`page-number-${i + 1}`}>
                <PaginationLink
                  path={`${i === 0 ? rootPage : rootPage + '/' + (i + 1)}`}
                  state={{ transition: false }}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ),
          )}
          {!isLast && (
            <PaginationItem className="next">
              <PaginationLink
                title="Volgende"
                aria-label="Ga een pagina verder"
                path={nextPage}
                state={{ transition: false }}
              >
                <FaChevronRight role="img" />
              </PaginationLink>
            </PaginationItem>
          )}
        </PaginationList>
      )}
    </PaginationWrap>
  )
}

export default Pagination

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Anchor from '../../../components/ui/anchor'
import OffCanvas, {
  OffCanvasHeader,
  OffCanvasBody,
} from '../../../components/ui/off-canvas'
import { BiSliderAlt } from 'react-icons/bi'
import { useMedia } from 'react-use-media'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { slugify } from '../../../utils/utilFunctions'
import { size } from '../../../theme'
import {
  SectionWrapper,
  OffCanvasTitle,
  Title,
  FilterButton,
  TabContainer,
  TabList,
  TabItem,
} from './styles'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const CategoryTabs = ({ type, path, content, current, parentCategory }) => {
  const allCategories = () => {
    if (type === 'Publicaties' || type === 'publicaties') {
      return content.publications.map((edge) => edge.node)
    } else if (type === 'Agenda' || type === 'agenda') {
      return content.events.map((edge) => edge.node)
    } else if (type === 'Subsidies' || type === 'subsidies') {
      return content.subsidies.map((edge) => edge.node)
    } else if (type === 'Projecten' || type === 'projecten') {
      return content.projects.map((edge) => edge.node)
    } else if (type === 'Contacten' || type === 'contacten') {
      return content.contacts.map((edge) => edge.node)
    } else {
      return content.news.map((edge) => edge.node)
    }
  }

  const allSubCategories = (theme) => {
    let list

    if (type === 'Publicaties' || type === 'publicaties') {
      list = content.publications
    } else if (type === 'Agenda' || type === 'agenda') {
      list = content.events
    } else if (type === 'Subsidies' || type === 'subsidies') {
      list = content.subsidies
    } else if (type === 'Projecten' || type === 'projecten') {
      list = content.projects
    } else {
      list = content.news
    }

    return list
      .filter((edge) => edge.node.subjects && edge.node.subjects.length > 0)
      .map((edge) => edge.node.subjects)
      .flat()
      .filter((subject) => {
        return subject.themes && Array.isArray(subject.themes) && subject.themes.filter((currentTheme) => currentTheme.id === theme).length > 0
      })
      .reduce(
        (unique, item) =>
          unique.find((object) => object.title === item.title)
            ? unique
            : [...unique, item],
        [],
      )
  }

  // Special sorting for contacts
  const allSubCategoriesTabs = () => {
    let list
    const hasCategories =
      allCategories().filter((item) => item.themes || item.role).length > 0

    if (hasCategories) {
      if (type === 'Contacten' || type === 'contacten') {
        list = allCategories()
          .map((item) =>
            item.role
              ? item.role.map((role) => {
                return { title: role.title, order: role.sortOrder }
              })
              : null,
          )
          .filter((item) => item)
          .flat()
          .sort((a, b) => {
            return a.order - b.order
          })
          .map((item) => item.title)
      } else {
        list = allCategories()
          .map((item) =>
            item.themes ? item.themes.map((theme) => theme.title) : false,
          )
          .filter((item) => item)
          .flat()
      }
    } else {
      list = false
    }

    if (list) {
      if (type === 'Contacten' || type === 'contacten') {
        return list
          .filter((item, index) => list.indexOf(item) === index)
          .filter((result) => result)
      } else {
        return list
          .filter((item, index) => list.indexOf(item) === index)
          .sort()
          .filter((result) => result)
      }
    }

    return false
  }

  const [offCanvasOpen, setOffcanvasOpen] = useState(false)
  const isMobile = useMedia({ maxWidth: size.small })
  const categoryList = allSubCategoriesTabs()

  const offCanvasHandler = () => {
    setOffcanvasOpen((prevState) => !prevState)
  }

  const Filters = () => {
    return (
      categoryList && (
        <TabContainer>
          <Title>Filteren</Title>

          <Anchor
            path={`${path}`}
            title="Verwijder alle filters"
            aria-label="Verwijder alle filters"
            className={!current ? 'active' : ''}
          >
            Alles
          </Anchor>

          {categoryList.map((categoryItem, index) => (
            <React.Fragment key={index}>
              {allSubCategories(categoryItem).length > 0 ? (
                <Accordion
                  allowZeroExpanded
                  preExpanded={
                    current === categoryItem ||
                      (parentCategory && parentCategory === categoryItem)
                      ? [slugify(categoryItem)]
                      : [0]
                  }
                >
                  <AccordionItem uuid={slugify(categoryItem)}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span
                          className={
                            current === categoryItem ||
                              (parentCategory && parentCategory === categoryItem)
                              ? 'active'
                              : ''
                          }
                        >
                          {categoryItem}
                        </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <TabList>
                        <TabItem
                          active={current === categoryItem}
                          className="active"
                        >
                          <Anchor
                            path={`${path}/${slugify(categoryItem)}`}
                            title={`Filter op ${categoryItem}`}
                            aria-label={`Filter op ${categoryItem}`}
                            state={{ transition: false }}
                          >
                            Alles
                          </Anchor>
                        </TabItem>

                        {allSubCategories(categoryItem).map((subItem) => (
                          <TabItem
                            active={current === subItem.title}
                            className="active"
                            key={subItem.id}
                          >
                            <Anchor
                              path={`${path}/${slugify(categoryItem)}/${slugify(
                                subItem.title,
                              )}`}
                              title={`Filter op ${subItem.title}`}
                              aria-label={`Filter op ${subItem.title}`}
                              state={{ transition: false }}
                            >
                              {subItem.title}
                            </Anchor>
                          </TabItem>
                        ))}
                      </TabList>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                <Anchor
                  path={`${path}/${slugify(categoryItem)}`}
                  title={`Filter op ${categoryItem}`}
                  aria-label={`Filter op ${categoryItem}`}
                  state={{ transition: false }}
                  className={current === categoryItem ? 'active' : ''}
                >
                  {categoryItem === 'Subsidie'
                    ? `${categoryItem}s`
                    : categoryItem}
                </Anchor>
              )}
            </React.Fragment>
          ))}
        </TabContainer>
      )
    )
  }

  return (
    <SectionWrapper>
      <Container>
        <Row>
          <Col>
            {isMobile && (
              <OffCanvas
                scrollable={true}
                isOpen={offCanvasOpen}
                onClick={offCanvasHandler}
              >
                <OffCanvasHeader onClick={offCanvasHandler}>
                  <OffCanvasTitle>Filteren</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                  <Filters />
                </OffCanvasBody>
              </OffCanvas>
            )}

            {isMobile ? (
              <FilterButton onClick={offCanvasHandler}>
                <BiSliderAlt />
                Filter
              </FilterButton>
            ) : (
              <Filters />
            )}
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  )
}

CategoryTabs.propTypes = {
  type: PropTypes.oneOf([
    'Nieuws',
    'nieuws',
    'Publicaties',
    'publicaties',
    'Agenda',
    'agenda',
    'Projecten',
    'projecten',
    'Contacten',
    'contacten',
    'Subsidies',
    'subsidies',
  ]),
  path: PropTypes.string,
  content: PropTypes.object,
  parentCategory: PropTypes.string,
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

CategoryTabs.defaultProps = {
  type: 'news',
  path: '/actueel/nieuws',
  subCategory: false,
}

export default CategoryTabs
